import { Icons, Button } from '@loveholidays/design-system';
import React from 'react';

import { SocialProfile } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';

export interface SocialProfilesProps extends ClassNameProps {
  items: SocialProfile[];
}

const IconMapping: Record<string, Icons> = {
  Blog: 'Content/MediaBlog',
};

export const SocialProfiles: React.FC<SocialProfilesProps> = ({ items, className }) => (
  <div
    data-id="social-profiles"
    className={className}
    sx={{
      maxWidth: ['80%', '100%'],
      marginX: ['auto', 0],
      display: 'flex',
      justifyContent: ['center', 'flex-start'],
      flexWrap: 'wrap',
    }}
  >
    {items.map(({ type, url }) => (
      <Button
        ariaLabel={type}
        variant="Outline"
        size="48"
        key={type}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        icon={IconMapping[type] || `Brands/${type}Filled`}
        sx={{
          marginX: '2xs',
          marginBottom: ['l', null, 0],
          backgroundColor: 'backgroundLightsubtle',
          ':first-of-type': {
            marginLeft: ['2xs', 0],
          },
          '&:visited': {
            backgroundColor: 'backgroundLightsubtle',
          },
        }}
      />
    ))}
  </div>
);
