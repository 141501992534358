import { Image } from '@loveholidays/design-system';
import React from 'react';

import { useAppContext } from '@Client/context/contexts';
import { ClassNameProps } from '@ComponentProps';

export const NewsletterImages: React.FC<ClassNameProps> = ({ className }) => {
  const { publicPath } = useAppContext();

  const images = [
    ['beach.jpg', 'palm-tree.jpg'],
    ['ocean-bay.jpg', 'blue-chairs.jpg'],
  ];

  return (
    <div className={className}>
      {images.map((imagegroup, i) => (
        <div
          key={i}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: i === 1 ? '4xl' : 0,
          }}
        >
          {imagegroup.map((image) => (
            <Image
              alt="newsletter-imagery"
              key={`${publicPath}Images/${image}`}
              src={`${publicPath}Images/${image}`}
              width={225}
              height={225}
              lazy
              fluid={false}
              sx={{
                margin: 's',
                borderRadius: '12',
              }}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
