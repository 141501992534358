import React, { useMemo } from 'react';
import { useQuery } from 'urql';

import getSocialProfiles from './getSocialProfiles.gql';
import { SocialProfiles } from './SocialProfiles';
import { Query } from '@AuroraTypes';
import { ClientError } from '@Core/errors/client/ClientError';
import { ContentNotFoundError } from '@Core/errors/client/ContentNotFoundError';
import { isQueryResolved } from '@Core/isQueryResolved';

export const SocialProfilesWrapper: React.FC = () => {
  const [{ error, data, fetching }] = useQuery<Query>({
    query: getSocialProfiles,
  });

  if (error?.graphQLErrors?.[0]) {
    const { message, extensions } = error.graphQLErrors[0];

    if (extensions?.code === '404') {
      throw new ContentNotFoundError('newsletter-srp');
    }

    throw new ClientError(message);
  } else if (!isQueryResolved<Query>(fetching, data)) {
    return null;
  } else if (!data.Content) {
    throw new ContentNotFoundError('newsletter-srp');
  }

  const { socialProfiles } = data.Content;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => <SocialProfiles items={socialProfiles} />, [socialProfiles]);
};
